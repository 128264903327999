import React from "react"
import { graphql } from "gatsby"

import { SEO } from "../components/SEO"
import { DefaultLayout } from "../layouts/Default"
import { Hero } from "../components/Hero"
import { Tag } from "../components/Tag"

const title = "Topics"
const description =
  "Browse React Native School by looking at classes and tutorials related to what you need to get done now."

const Topics = ({ data }) => {
  return (
    <DefaultLayout>
      <SEO title={title} description={description} />
      <Hero title={title} subtitle={description} />

      <div className="py-10">
        <section className="container mx-auto px-8 max-w-screen-md flex flex-wrap">
          {data.topics.edges.map(({ node }) => (
            <div className="my-3" key={node.slug}>
              <Tag slug={node.slug} name={node.name} />
            </div>
          ))}
        </section>
      </div>
    </DefaultLayout>
  )
}

export default Topics

export const query = graphql`
  query {
    topics: allTopicsYaml(sort: { fields: name, order: ASC }) {
      edges {
        node {
          name
          id
          slug
        }
      }
    }
  }
`
